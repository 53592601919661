import React from 'react';

const Investigation = () => {
    return (
        <div>
            <h1>Investigation page</h1>
        </div>
    );
};

export default Investigation;