import React, { useState } from 'react';
import {
    FaTh,
    FaBars,
    FaWallet,
    FaNotesMedical,
    FaShoppingBag,
    FaHospitalUser,
    FaPrescriptionBottleAlt
}from "react-icons/fa";
// import { FaKitMedical } from "react-icons/fa6";
import { NavLink } from 'react-router-dom';


const Sidebar = ({children}) => {
    const[isOpen ,setIsOpen] = useState(false);
    const toggle = () => setIsOpen (!isOpen);
    const menuItem=[
        {
            path:"/",
            name:"Dashboard",
            icon:<FaTh/>
        },
        {
            path:"/laboratory",
            name:"Laboratory",
            icon:<FaPrescriptionBottleAlt />
        },
        {
            path:"/patients",
            name:"Patients",
            icon:<FaHospitalUser/>
        },
        {
            path:"/billing",
            name:"Billing",
            icon:<FaWallet/>
        },
        {
            path:"/investigation",
            name:"Investigation",
            icon:<FaNotesMedical/>
        },
        {
            path:"/pharmacy",
            name:"Phamarcy",
            icon:<FaNotesMedical/>
        }
    ]
    return (
        <div className="container">
           <div style={{width: isOpen ? "500px" : "50px"}} className="sidebar">
               <div className="top_section">
                   <h1 style={{display: isOpen ? "block" : "none"}} className="logo">Logo</h1>
                   <div style={{marginLeft: isOpen ? "360px" : "0px"}} className="bars">
                       <FaBars onClick={toggle}/>
                   </div>
               </div>
               {
                   menuItem.map((item, index)=>(
                       <NavLink to={item.path} key={index} className="link flex items-center" activeclassName="active">
                           <div className="icon">{item.icon}</div>
                           <div style={{display: isOpen ? "block" : "none"}} className="link_text">{item.name}</div>
                       
                       </NavLink>

                   ))
               }
               
{/* <li style={{display: isOpen ? "block" : "none"}} className="link_text">Logout</li> */}
           </div>
           <main>{children}</main>
        </div>
    );
};

export default Sidebar;