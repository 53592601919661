import React from 'react';
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Sidebar from './components/Sidebar';
import Dashboard from './pages/Dashboard.jsx';
import Laboratory from './pages/Laboratory.jsx';
import Billing from './pages/Billing.jsx';
import Patients from './pages/Patients.jsx';
import Investigation from './pages/Investigation.jsx';
import Pharmacy from './pages/Pharmacy.jsx';

const App = () => {
  return (
    <BrowserRouter>
      <Sidebar>
        <Routes>
          <Route path="/" element={<Dashboard />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/laboratory" element={<Laboratory />} />
          <Route path="/patients" element={<Patients />} />
          <Route path="/billing" element={<Billing />} />
          <Route path="/investigation" element={<Investigation />} />
          <Route path="/pharmacy" element={<Pharmacy />} />

        </Routes>
      </Sidebar>
    </BrowserRouter>
  );
};

export default App;